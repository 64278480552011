import {
  type Component,
  Show,
  For,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { Dynamic } from "solid-js/web";
import { A } from "@solidjs/router";
import { checkError } from "~/services/roma-api/checkError";
import { APIError } from "~/services/roma-api/errors";
import { Icon } from "solid-heroicons";
import {
  exclamationCircle,
  shieldCheck,
  square_2Stack,
  flag,
  checkCircle,
  chevronUp,
  chevronDown,
} from "solid-heroicons/outline";
import { useErrorContext } from "~/utils/contexts";
import * as Sentry from "@sentry/browser";

type GenericErrorProps = {
  class?: string;
  error: Error | APIError;
  reset?: () => void;
  title?: string;
  actions?: { label: string; action?: () => void }[];
  size?: "sm" | "md" | "lg";
  autoReport?: boolean;
  hideReporting?: boolean;
};

export const GenericError: Component<GenericErrorProps> = (props) => {
  const { reportErrorToSentry } = useErrorContext();
  const err = checkError(props.error);
  const formattedError = err instanceof APIError ? err : null;
  let feedbackRef: HTMLFormElement | undefined;

  const [isReported, setIsReported] = createSignal(
    formattedError?.getReportStatus().attempted || false
  );
  const [sentryEventId, setSentryEventId] = createSignal(
    formattedError?.getReportStatus().meta?.eventId
  );
  const [showCopied, setShowCopied] = createSignal(false);
  const [showFeedback, setShowFeedback] = createSignal(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = createSignal(false);


  onMount(() => {
    if (props.autoReport && err && !isReported()) {
      const eventId = reportErrorToSentry(err);
      if (eventId) {
        setSentryEventId(eventId);
        setIsReported(true);
      }
    }
  });

  const handleReportError = () => {
    if (err) {
      const eventId = reportErrorToSentry(err);
      if (eventId) {
        setSentryEventId(eventId);
        setIsReported(true);
      }
    }
  };

  const handleSubmitFeedback = () => {
    if (!feedbackRef) return;

    const formData = new FormData(feedbackRef);
    const messageValue = formData.get("message") as string;

    if (messageValue && sentryEventId()) {
      Sentry.captureFeedback(
        {
          associatedEventId: sentryEventId(),
          message: messageValue,
          name: (formData.get("name") as string) || undefined,
          email: (formData.get("email") as string) || undefined,
        },
        { includeReplay: true }
      );

      // TODO Sentry
      // Check if email/name can be added to Sentry context for subsequent feedback submissions and autopopulate the fields
      // TODO add some validation on the basic submission form, working for now.

      feedbackRef.reset();
      setFeedbackSubmitted(true);
    }
  };

  const handleCopyId = () => {
    if (!sentryEventId()) return;
    navigator.clipboard
      .writeText(sentryEventId())
      .then(() => {
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };



  createEffect(() => {
    if (import.meta.env.DEV) {
      console.log(
        "[GenericError.tsx]: formattedError error >> ",
        formattedError
      );
    }
  });

  return (
    <div
      class={`flex flex-col ${
        props.size === "sm" ? "gap-1" : "gap-2"
      }  bg-red-100 p-4`}
      classList={{ [`${props.class}`]: !!props.class }}
    >
      <div class="flex items-center mb-2">
        <Icon path={exclamationCircle} class="w-6 h-6 mr-2" />
        <p class="text-lg font-medium">{props.title || "An error occurred"}</p>
        <Show when={!!props.reset}>
          <button
            class="text-sm ml-auto  active:translate-y-0.5 rounded-md bg-white px-2 py-1 border border-gray-300"
            onClick={props.reset}
          >
            Reset/Retry
          </button>
        </Show>
      </div>
      <p class="py-2">{props.error.message}</p>

      <Show when={!!props.actions}>
        <ul class="list-inside list-disc flex flex-col gap-1">
          <p class="font-medium pb-2">Suggested Actions:</p>
          <For each={props.actions}>
            {(action) => {
              return (
                <li>
                  <Dynamic
                    component={action.action ? "button" : "p"}
                    onClick={action.action ? action.action : undefined}
                    class={`${action.action ? "text-roma-blue" : ""}`}
                  >
                    {action.label}
                  </Dynamic>
                </li>
              );
            }}
          </For>
        </ul>
      </Show>

      <Show when={import.meta.env.DEV}>
        <div class="bg-white rounded-lg border border-gray-300 p-3">
          <p>Details (DEV):</p>
          <Show when={formattedError}>
            <pre class="whitespace-pre text-xs">
              {JSON.stringify(
                { ...formattedError?.getDebugInfo(), stack: undefined },
                null,
                2
              )}
            </pre>
            <pre class=" whitespace-pre-line text-xs">
              <details>
                <summary>Stack</summary>
                <pre class="select-all">
                  {formattedError?.getDebugInfo().stack}
                </pre>
              </details>
            </pre>
          </Show>
        </div>
      </Show>

      <p>
        If this error persists, please contact{" "}
        <A href="/support" class="text-roma-blue">
          Customer Support
        </A>
        . We apologize for any inconvenience.
      </p>

      <Show when={!props.hideReporting}>
        <div class="flex flex-col gap-2 border-l-2 border-roma-dark-grey pl-3 py-0.5">
          <Show
            // when={false}
            when={isReported() && sentryEventId()}
            fallback={
              <>
                <div class="flex items-center">
                  <Icon path={flag} class="w-4 h-4 mr-1" />
                  <p>This error has not been reported.</p>
                </div>
                <button
                  type="button"
                  onClick={handleReportError}
                  class="text-sm px-2 py-0.5 bg-white text-roma-dark-grey self-start rounded-[4px] flex items-center active:translate-y-0.5 border border-gray-300"
                >
                  <span>Report</span>
                </button>
              </>
            }
          >
            {/* <div class="flex flex-col gap-2 border-l-2 border-roma-dark-grey pl-3 py-0.5"> */}
            <div class="flex items-center">
              <Icon path={shieldCheck} class="w-5 h-5 mr-1.5" />
              <p>This error has been reported.</p>
            </div>
            <div class="border border-gray-300 relative bg-white/80 rounded-[4px] text-roma-medium-grey font-light self-start px-2 py-0.5 flex items-center">
              <span class="select-all font-mono text-sm">
                ID:{sentryEventId()}
              </span>
              <button type="button" onClick={handleCopyId}>
                <Icon path={square_2Stack} class="ml-1 w-4 h-4" />
              </button>
              {/* TODO - Add Motion fade-in/out */}
              <Show when={showCopied()}>
                <div class="absolute -right-2 translate-x-full bg-roma-grey rounded-[4px] text-roma-dark-grey px-1 py-0.5 text-xs">
                  Copied!
                </div>
              </Show>
            </div>
            {/* </div> */}

            <Show
              // HERE
              // when={props.notification.allowFeedback}
              when={true}
            >
              <button
                onClick={() => setShowFeedback((prev) => !prev)}
                type="button"
                class="self-start flex items-center"
              >
                <span>Submit Feedback</span>
                <Icon
                  path={showFeedback() ? chevronUp : chevronDown}
                  class="w-3 h-3 ml-1"
                  stroke-width={2}
                />
              </button>
              <Show when={showFeedback()}>
                <div class="class flex flex-col gap-2 mt-5">
                  <Show
                    when={feedbackSubmitted()}
                    fallback={
                      <form
                        ref={(ref) => (feedbackRef = ref)}
                        action=""
                        class="flex flex-col gap-2"
                      >
                        <p>
                          Your insights help us understand what went wrong and
                          improve your experience. Please share any details
                          about what happened when this error occurred, and our
                          team will work to resolve it as quickly as possible.
                          Thank you for helping us make Roma better!
                        </p>
                        <div class="flex flex-col md:flex-row gap-2 md:items-center">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            class="w-full px-2 py-1 bg-white/90 rounded-[4px] border border-gray-300 text-sm text-roma-dark-grey placeholder:text-sm"
                            placeholder="Name (Optional)"
                          />
                          <input
                            type="text"
                            id="email"
                            name="email"
                            class="w-full px-2 py-1 bg-white/90 rounded-[4px] border border-gray-300 text-sm text-roma-dark-grey placeholder:text-sm"
                            placeholder="Email (Optional)"
                          />
                        </div>
                        <textarea
                          id="message"
                          name="message"
                          class="w-full p-2 rounded bg-white/90 text-roma-dark-grey border border-gray-300 text-sm placeholder:text-sm"
                          placeholder="What were you trying to do when this error occurred?"
                          rows={4}
                        />
                        <button
                          onClick={handleSubmitFeedback}
                          type="button"
                          class="bg-white border border-gray-300 px-2 py-1  text-sm rounded-[4px] text-roma-dark-grey active:translate-y-0.5"
                        >
                          Submit Feedback
                        </button>
                      </form>
                    }
                  >
                    <div class="flex items-center">
                      <Icon path={checkCircle} class="w-5 h-5 mr-1" />
                      <span class="font-medium">
                        Thank you for your feedback!{" "}
                      </span>
                    </div>
                    <p>
                      We’ve received your report and are looking into the issue.
                      Your input helps us improve and provide a better
                      experience. We appreciate your patience and support!
                    </p>
                    <p>
                      To reference this issue, please copy the above error ID,
                      or screenshot this notification.
                    </p>
                  </Show>
                </div>
              </Show>
            </Show>
          </Show>
        </div>
      </Show>
    </div>
  );
};
